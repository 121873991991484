.loading-div {
    position: fixed;
    padding-top: 15%;
    background-color: rgba(34, 34, 34, 0.95);
    z-index: 100;
    width: 100%;
    height: 100%;
}
.loading-div > img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    transform: scale(1);
    animation: pulse 1s linear infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
    }
    70% {
        transform: scale(1);
    }
    100% {
        transform: scale(0.95);
    }
}